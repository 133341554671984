import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import PaymentSubscription from "./PaymentSubscription";
import PaymentShareride from "./PaymentShareride";

const PaymentApproval = () => {
  const [data, setData] = useState();
  return (
    <aside>
      <TopHeader type="paymentapproval" />

      <div className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Subscription">
            <PaymentSubscription setData={setData} />
          </Tab>
          <Tab eventKey="Detail" title="Share Ride">
            <PaymentShareride data={data} />
          </Tab>
        </Tabs>
      </div>
    </aside>
  );
};

export default PaymentApproval;
