import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SignIn from "./views/auth/SignIn";
import "./App.scss";
import "./responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./views/screens/Dashboard/dashboard";
import Layout from "./Layout/Layout";
import AdminPage from "./views/screens/Admin/admin";
import Roles from "./views/screens/Roles/RolesTest";
import Drivers from "./views/screens/Drivers/drivers";
import Users from "./views/screens/Users/users";
import Booking from "./views/screens/Booking/Booking";
import AddRoles from "./views/screens/Roles/addRoles";
import AddDriver from "./views/screens/Drivers/driverProfile";
import DriverDocument from "./views/screens/Driver_Request/driver-document";
import DriverRequest from "./views/screens/Driver_Request/driver-request";
import DriverHistory from "./views/screens/Drivers/driverHistory";
import UserProfile from "./views/screens/Users/userProfile";
import UserHistory from "./views/screens/Users/userHistory";
import BookingHistory from "./views/screens/Booking/viewBooking";
import Coupon from "./views/screens/Coupons/coupon";
import AddCoupon from "./views/screens/Coupons/addCoupon";
import Subscription from "./views/screens/Subscription/subscription";
import HelpSupport from "./views/screens/Help&Support/helpAndSupport";
import ReferEarn from "./views/screens/Refer_and_Earn/referEarn";
import Setting from "./views/screens/Settings/setting";
import CancelRide from "./views/screens/Cancel_Ride/cancel_ride";
import DriversActive from "./views/screens/ActiveLog/activeLog";
import AddSetting from "./views/screens/Settings/addSetting";
import ReviewAndRating from "./views/screens/Review&Ratings/reviewRating";
import PushNotification from "./views/screens/Notification/pushNotification";
import OfflineBooking from "./views/screens/Offline_Ride/offlineBooking";
import AddOfflineBooking from "./views/screens/Offline_Ride/addOfflineBooking";
import ScheduleRide from "./views/screens/ScheduleRide/scheduleRideTest";
import ViewScheduleRide from "./views/screens/ScheduleRide/viewScheduleRide";
import PayTax from "./views/screens/Pay_Tax/payTax";
import ForgotPassword from "./views/auth/forgotPassword";
import ResetPassword from "./views/auth/resetPassword";
import Subscribtion_Detailed_View from "./views/screens/Subscription/Subscribtion_Detailed_View";
import ActiveLogList from "./views/screens/ActiveLog/ActiveLogList";
import ReportCopy from "./views/screens/Report/report copy";
import AddFareSetting from "./views/screens/Settings/addFare";
import FourNotFourPage from "./views/errorScreens/404";
import FourHandredPage from "./views/errorScreens/400";
import ViewRides from "./views/screens/Cancel_Ride/viewRides";
import Notification from "./views/screens/Notification/notification";
import AddAdmin from "./views/screens/Admin/addAdmin";
import ChangePassword from "./views/auth/changePassword";
import AddZone from "./views/screens/Settings/addZone";
import AddSubscription from "./views/screens/Subscription/addSubscription";
import OfflineHistory from "./views/screens/Offline_Ride/viewOfflineBooking";
import Invoice from "./views/screens/Booking/invoice";
import BookingList from "./views/screens/Booking/BookingList";
import AddPeak from "./views/screens/Settings/addPeak";
import PrivateRoutes from "./constants/PrivateRoutes";
// import useToken from "./constants/Storage/useToken";
import useUser from "./constants/Storage/userData";
import Vehicles from "./views/screens/Vehicle_Category/Vehicles";
import AddVehicle from "./views/screens/Vehicle_Category/addVehicle";
import DriverTab from "./views/screens/Drivers/DriverTab";
import RevenueList from "./views/screens/Revenue/RevenueList";
import ShareCab from "./views/screens/ShareCab/ShareCab";

import PrivateRoute2 from "./constants/PrivateRoute2";
import Layout2 from "./Layout/Layout2";
import Dashboard2 from "./views/screens/Dashboard/Dashboars2";
import EditVehicleTypes from "./views/screens/Settings/EditVehicleTypes";
import ZoneSettings from "./views/screens/Settings/ZoneSettings";
import Company from "./views/screens/Company/Company";
import AddCompany from "./views/screens/Company/AddCompany";
import CompanyAdd from "./views/screens/Settings/companyAdd";
import CompanyLogin from "./companyLogin/auth/CompanyLogin";
import CompanyOfflineBooking from "./companyLogin/screens/offlineBooking/CompanyOfflineBooking";
import CompanyAddOffline from "./companyLogin/screens/offlineBooking/CompanyAddOffline";
import CompanyOfflineView from "./companyLogin/screens/offlineBooking/CompanyOfflineView";
import CompanyBookingView from "./companyLogin/screens/offlineBooking/CompanyBookingView";
import ShareCabCreate from "./views/screens/ShareCab/ShareCabCreate";
import CompanyShareCab from "./companyLogin/screens/shareCab/CompanyShareCab";
import CompanyShareCabCreate from "./companyLogin/screens/shareCab/CompanyShareCabCreate";
import CompanyBookingList from "./companyLogin/screens/bookingHistory/CompanyBookingList";
import CompanyCancelRide from "./companyLogin/screens/cancelOrder/CompanyCancelRide";
import CompanyCancelView from "./companyLogin/screens/cancelOrder/CompanyCancelView";
import CompanyScheduleLisst from "./companyLogin/screens/scheduleRide/CompanyScheduleLisst";
import CompanyScheduleRideView from "./companyLogin/screens/scheduleRide/CompanyScheduleRideView";
import CompanyDriverList from "./companyLogin/screens/drivers/CompanyDriverList";
import CompanyDriverProfileView from "./companyLogin/screens/drivers/CompanyDriverProfileView";
import CompanyRevenueList from "./companyLogin/screens/revenue/CompanyRevenueList";
import UserNotification from "./views/screens/Notification/userNotification";
import Requests from "./views/screens/Driver_Request/request";
import UserRequest from "./views/screens/Driver_Request/UserRequest";
import DriverDocumentIndividual from "./views/screens/Driver_Request/DriverDocumentIndividual";
import PaymentApproval from "./companyLogin/screens/PaymentApproval/PaymentApproval";
import Places from "./companyLogin/screens/Place/Places";
import AddPlace from "./companyLogin/screens/Place/AddPlace";

export const ReactRoute = () => {
  // const { token } = useToken();
  // const { user } = useUser();
  const token = localStorage.getItem("token");
  return (
    <Routes>
      <Route path="/login" index element={<SignIn />} />
      <Route path="/company/login" index element={<CompanyLogin />} />
      <Route path="/forgot-password" index element={<ForgotPassword />} />
      <Route path="/reset-password" index element={<ResetPassword />} />
      <Route element={<PrivateRoutes token={token} />}>
        <Route path="/change-password" index element={<ChangePassword />} />
        <Route
          path="/booking/view-booking/invoice"
          index
          element={<Invoice />}
        />
        <Route element={<Layout />}>
          <Route path="/" index element={<Dashboard />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/Admin" index element={<AdminPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/Admin/add-admin" index element={<AddAdmin />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/role" index element={<Roles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/roles/add-roles" index element={<AddRoles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/driver" index element={<DriverTab />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/driver/view-profile" index element={<AddDriver />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/driver/view-profile/history"
            index
            element={<DriverHistory />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/driver-request" index element={<DriverRequest />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/driver-request/document"
            index
            element={<DriverDocument />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/user" index element={<Users />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/company-nav" index element={<Company />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/company-nav/add-company"
            index
            element={<AddCompany />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/user/view-profile" index element={<UserProfile />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/user/view-profile/history"
            index
            element={<UserHistory />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/booking" index element={<BookingList />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/booking/view-booking"
            index
            element={<BookingHistory />}
          />
        </Route>
        <Route
          path="/booking/view-booking/invoice"
          index
          element={<Invoice />}
        />
        <Route element={<Layout />}>
          <Route path="/coupon-list" index element={<Coupon />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/coupon/add-coupon" index element={<AddCoupon />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/subscription" index element={<Subscription />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/subscription/detail-view"
            index
            element={<Subscribtion_Detailed_View />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/subscription-add" index element={<AddSubscription />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/help-support" index element={<HelpSupport />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/refer-earn" index element={<ReferEarn />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting" index element={<Setting />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/vehicle-edit" index element={<EditVehicleTypes />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/zone-setting" index element={<ZoneSettings />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/add-setting" index element={<AddSetting />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/add-zone" index element={<AddZone />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/company-add" index element={<CompanyAdd />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/setting/add-fare-setting"
            index
            element={<AddFareSetting />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/add-peak-setting" index element={<AddPeak />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/cancelled" index element={<CancelRide />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/cancelled/view" index element={<ViewRides />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/activeLog" index element={<DriversActive />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/activeLog/view-list"
            index
            element={<ActiveLogList />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/revenue-list" index element={<RevenueList />} />
        </Route>
        <Route element={<Layout />}>
          {/* <Route path="/report" index element={<Report />} /> */}
          <Route path="/report" index element={<ReportCopy />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/review-rating" index element={<ReviewAndRating />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/share-cab" index element={<ShareCab />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/push-notification"
            index
            element={<PushNotification />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/notification" index element={<Notification />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/offline-booking" index element={<OfflineBooking />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/offline-booking/add-booking"
            index
            element={<AddOfflineBooking />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/share-cab-create" index element={<ShareCabCreate />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/offline-booking/view-booking"
            index
            element={<OfflineHistory />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/schedule-ride" index element={<ScheduleRide />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/schedule-ride/view"
            index
            element={<ViewScheduleRide />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/pay-tax" index element={<PayTax />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/vehicle-category" index element={<Vehicles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/vehicle" index element={<AddVehicle />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/pushnotification/view"
            index
            element={<UserNotification />}
          />
        </Route>
      </Route>
      <Route element={<PrivateRoute2 />}>
        <Route
          path="/company-change-password"
          index
          element={<ChangePassword />}
        />
        <Route element={<Layout2 />}>
          <Route path="/company/driver" index element={<CompanyDriverList />} />

          <Route
            path="/driver-request-individual/document"
            index
            element={<DriverDocumentIndividual />}
          />

          <Route path="/company/request-list" index element={<UserRequest />} />


          <Route path="/company/payment-approval" index element={<PaymentApproval />} />

          <Route path="/company/places" index element={<Places />} />
          <Route path="/company/addplaces" index element={<AddPlace />} />

          <Route
            path="/company/document"
            index
            element={<DriverDocumentIndividual />}
          />

          <Route
            path="/company/driver/view"
            index
            element={<CompanyDriverProfileView />}
          />
          <Route path="/company" index element={<Dashboard2 />} />
          <Route
            path="/company/booking"
            index
            element={<CompanyBookingList />}
          />
          <Route
            path="/company/booking/view"
            index
            element={<CompanyBookingView />}
          />
          <Route
            path="/company/subscription"
            index
            element={<Subscription />}
          />
          <Route
            path="/company/offline-booking"
            index
            element={<CompanyOfflineBooking />}
          />
          <Route
            path="/company/offline-booking/add-booking"
            index
            element={<CompanyAddOffline />}
          />
          <Route
            path="/company/offline-booking/view-booking"
            index
            element={<CompanyOfflineView />}
          />
          <Route
            path="/company/offline-booking/view"
            index
            element={<CompanyBookingView />}
          />
          <Route
            path="/company/revenue-list"
            index
            element={<CompanyRevenueList />}
          />
          <Route
            path="/company/share-cab"
            index
            element={<CompanyShareCab />}
          />
          <Route
            path="/company/share-cab/create"
            index
            element={<CompanyShareCabCreate />}
          />
          <Route path="/company/setting" index element={<Setting />} />
          <Route
            path="/company/cancelled"
            index
            element={<CompanyCancelRide />}
          />
          <Route
            path="/company/cancelled/view"
            index
            element={<CompanyCancelView />}
          />
          <Route
            path="/company/schedule-ride"
            index
            element={<CompanyScheduleLisst />}
          />
          <Route
            path="/company/schedule-ride/view"
            index
            element={<CompanyScheduleRideView />}
          />
        </Route>
      </Route>

      <Route path="*" index element={<FourNotFourPage />} />

      <Route path="/page-not-found" index element={<FourHandredPage />} />
    </Routes>
  );
};
