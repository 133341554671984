import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  useCreateShareCabRideMutation,
  useEditShareCabRideMutation,
  useLazyDriverListNewQuery,
  useLazyListPlaceQuery,
  useLazyViewShareCabQuery,
} from "../../../constants/redux/api/api";
import { TbArmchair2 } from "react-icons/tb";
import { logDOM } from "@testing-library/react";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { MAPS_API_KEY } from "../../../constants/redux/api/constants";
import axios from "axios";
function ShareCabCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const status = location?.state?.data?.status;
  // console.log(location, "loca");
  const [list, setList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [vechicle, setVechicle] = useState("");
  const [seatNo, setSeatNo] = useState("");
  const [seatList, seatsList] = useState([]);
  const [place, setPlace] = useState([]);
  const [driver, setDriver] = useState("");
  const [from, setForm] = useState("");
  const [to, setTo] = useState("");
  const [driverErr, setDriverErr] = useState(false);
  const [fromErr, setFormErr] = useState(false);
  const [toErr, setToErr] = useState(false);
  const [pointNum, setPointsNum] = useState("");
  const [pointNumErr, setPointsNumErr] = useState(false);
  const [distance , setDistance] = useState("");
  const [pointslist, setPointsList] = useState([]);
  const [rideOn, setRideOn] = useState("");
  const [rideOnErr, setRideOnErr] = useState(false);
  const [rideId, setRideId] = useState("");
  const [pointsErr, setPointsErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [rideTime, setRideTime] = useState("");
  const [rideTimeErr, setRideTimeErr] = useState(false);

  const user = JSON.parse(localStorage.getItem("userdetails"));
  // console.log(user);

  //RTK
  const [driversListApi] = useLazyDriverListNewQuery();
  const [placeListApi] = useLazyListPlaceQuery();
  const [createsharecab] = useCreateShareCabRideMutation();
  const [viewShareCab] = useLazyViewShareCabQuery();
  const [editShareCabRide] = useEditShareCabRideMutation();
  const crateShare = () => {
    let empty;
    if (pointslist?.length > 0) {
      pointslist?.map((item) => {
        empty = Object.values(item).some(
          (x) => x === null || x === "" || x === " "
        );
      });
    }
    // console.log(empty, "empty");
    if (
      driver?.length == "" ||
      from?.length == "" ||
      empty == true ||
      to?.length == "" ||
      // pointNum?.length == "" ||
      rideOn?.length == "" ||
      rideTime?.length == ""
    ) {
      setDriverErr(true);
      setFormErr(true);
      setPointsErr(true);
      setToErr(true);
      // setPointsNumErr(true);
      setRideOnErr(true);
      setRideTimeErr(true);
    } else {
      console.log("tt");
      setBtn(true);
      dispatch(saveLoader(true));
      const fromdata = new FormData();
      fromdata.append("admin_id", user?.id);
      fromdata.append("ride_id", rideId);
      fromdata.append("driver_id", driver);
      fromdata.append("from_place_id", from);
      fromdata.append("to_place_id", to);
      fromdata.append("distance", 5); // need to change
      const rideTimes = [rideOn, rideTime];
      fromdata.append("ride_on", rideTimes.toString());
      seatList?.map((item, ind) => {
        fromdata.append(`seat_details[${ind}][seat_no]`, item?.seat_no);
        fromdata.append(`seat_details[${ind}][amount]`, item?.amount);
      });
      pointslist?.map((item, ind) => {
        const timing = [rideOn, item?.time];
        fromdata.append(`point_details[${ind}][place_id]`, item?.points);
        fromdata.append(`point_details[${ind}][ride_time]`, timing?.toString());
        fromdata.append(`point_details[${ind}][position]`, item?.position);
      });
      if (location?.state?.type == "view") {
        editShareCabRide({ payload: fromdata, id: location?.state?.id })
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            // console.log(res);
            navigate("/share-cab", { state: { tab: "Detail" } });
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log(err);
          });
      } else {
        createsharecab(fromdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            dispatch(saveLoader(false));
            // console.log(res);
            navigate("/share-cab", { state: { tab: "Detail" } });
          })
          .catch((err) => {
            setBtn(false);
            dispatch(saveLoader(false));
            console.log(err);
          });
      }
    }
  };

  useEffect(()=>{calculateDistance()},[from,to])

  const calculateDistance = async () => {
    if(!from || !to){
      return
    }
    // const origin = '37.7749,-122.4194'; // San Francisco
    // const destination = '34.0522,-118.2437'; // Los Angeles

    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${from}&destinations=${to}&key=${MAPS_API_KEY}`;

    try {
      const response = await fetch(url);
      const result = response.data;
      const distanceInMeters = result.rows[0].elements[0].distance.value;
      console.log((distanceInMeters/1000).toFixed(1),"distance")
      setDistance((distanceInMeters/1000).toFixed(1));
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }

  const apiplaceList = () => {
    placeListApi()
      .unwrap()
      .then((res) => {
        setPlace(res?.places);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getList = () => {
    const params = "";
    driversListApi(params)
      .unwrap()
      .then((res) => {
        setList(res?.drivers);
        setFilter(res?.drivers);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getView = () => {
    viewShareCab(location?.state?.id)
      .unwrap()
      .then((res) => {
        // console.log(res, "res");
        const data = res?.ride;
        setDriver(data?.driver?.id);
        setRideId(data?.driver?.ride_id?.id);
        setVechicle(data?.driver?.ride_id?.name);
        setSeatNo(data?.ride_id?.no_of_seats);
        seatsList(data?.ride_id?.ride_seat_prices);
        setForm(data?.from_place?.id);
        setTo(data?.to_place?.id);
        const rideDate = data?.ride_on.split(" ");
        setRideOn(rideDate[0]);
        setRideTime(rideDate[1])
        const temp = [];
        data?.share_cab_points?.map((item, ind) => {
          const date = item?.ride_time.split(" ");
          //  console.log(date,"date");
          temp.push({
            points: item?.place?.id,
            position: item?.position,
            date: date[0],
            time: date[1],
          });
        });
        setPointsList(temp);
        setPointsNum(data?.share_cab_points?.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (location?.state?.id) {
      getView();
    }
  }, []);
  useEffect(() => {
    getList();
    apiplaceList();
  }, []);

  const handelClick = (e) => {
    setDriver(e);
    const finds = filter?.find((item) => item?.id == e);
    setRideId(finds?.ride_id?.id);
    setVechicle(finds?.ride_id?.name);
    setSeatNo(finds?.ride_id?.no_of_seats);
    seatsList(finds?.ride_id?.ride_seat_prices);
  };

  const handelNum = (e) => {
    const temp = [];
    // let i = 0;
    // for (i == 0; i < e; i++) {
    temp.push(...pointslist, { points: "", position: "", time: "" });
    // }
    // console.log(temp);
    setPointsList(temp);
  };

  const DeletePoints = (e) => {
    console.log(e);
    const temp = [...pointslist];
    temp.splice(e, 1);
    setPointsList(temp);
  };
  // const handelNum = (e) => {
  //   const temp = [];
  //   let i = 0;
  //   for (i == 0; i < e; i++) {
  //     temp.push({ points: "", position: "", time: "" });
  //   }
  //   // console.log(temp);
  //   setPointsList(temp);
  //   setPointsNum(e);
  // };

  const handelPoints = (e, type, ind) => {
    // console.log(e);
    let temp = [...pointslist];
    temp[ind] = {
      ...temp[ind],
      [type]: e,
    };
    setPointsList(temp);
  };
  console.log(pointslist);
  return (
    <aside>
      <TopHeader />

      <article>
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <div className="flex-ac-jb">
              <p
                className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                for=""
              ></p>
              <span>Share cab Create</span>
            </div>

            <div className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="label-off" for="">
                      Driver
                    </label>
                    <div className="seac-box">
                      <select
                        value={driver}
                        onChange={(e) => handelClick(e.target.value)}
                        className="bg-transparent"
                        disabled={
                          location?.state?.type == "view" && status != 0
                            ? true
                            : false
                        }
                      >
                        <option>Select Driver</option>
                        {list?.map((item, ind) => {
                          return (
                            <>
                              <option value={item?.id}>{item?.name}</option>;
                            </>
                          );
                        })}
                      </select>
                      {driver?.length == "" && driverErr && (
                        <p className="err-p">Enter Drive </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="label-off" for="">
                      Vehicle Name
                    </label>
                    <div className="seac-box">
                      <input
                        type="text"
                        id="example-if-email"
                        name="Seat"
                        value={vechicle}
                        // onChange={(e) => setVechicle(e.target.value)}
                        placeholder="vehicle name"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              {seatList?.map((item, ind) => {
                return (
                  <div className="px-1 col-sm-6 modal-label-input" key={ind}>
                    <label className="text-primary-dark" for="">
                      <TbArmchair2 style={{ width: "30px", height: "25px" }} />
                    </label>
                    <div style={{ position: "relative", marginBottom: "10px" }}>
                      <input
                        type="text"
                        id="example-if-email"
                        name="Seat"
                        value={item?.seat_no}
                        placeholder="Seat"
                        disabled
                      />
                    </div>
                    <div style={{ position: "relative", marginBottom: "10px" }}>
                      <input
                        type="number"
                        id="example-if-email"
                        name="Price"
                        value={item?.amount}
                        placeholder="Price"
                        disabled
                      />
                    </div>
                  </div>
                );
              })}
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="px-1 col-sm-12 modal-label-input">
                  <label className="text-primary-dark">Seat</label>
                  <input
                    type="number"
                    id="example-if-email"
                    name="Seat"
                    value={seatNo}
                    onChange={(e) => setSeatNo(e.target.value)}
                    placeholder="Seat"
                    disabled
                  />
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      From
                    </label>

                    <select
                      value={from}
                      onChange={(e) => setForm(e.target.value)}
                      disabled={
                        location?.state?.type == "view" && status != 0
                          ? true
                          : false
                      }
                    >
                      <option>Select From</option>
                      {place?.map((item) => {
                        return <option value={item?.id}>{item?.title}</option>;
                      })}
                    </select>
                    {from?.length == "" && fromErr && (
                      <p className="err-p">Enter From </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      To
                    </label>

                    <select
                      value={to}
                      onChange={(e) => setTo(e.target.value)}
                      disabled={
                        location?.state?.type == "view" && status != 0
                          ? true
                          : false
                      }
                    >
                      <option>Select To</option>
                      {place?.map((item) => {
                        return <option value={item?.id}>{item?.title}</option>;
                      })}
                    </select>
                    {to?.length == "" && toErr && (
                      <p className="err-p">Enter To </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="label-off" for="">
                      Ride On
                    </label>
                    <input
                      value={rideOn}
                      disabled={
                        location?.state?.type == "view" && status != 0
                          ? true
                          : false
                      }
                      placeholder="Ride Time"
                      name="date"
                      className="bg-transparent"
                      type="date"
                      onChange={(e) => setRideOn(e.target.value)}
                    />
                    {rideOn?.length == "" && rideOnErr && (
                      <p className="err-p">Enter Ride Date</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="label-off" for="">
                      Ride Time
                    </label>
                    <input
                      value={rideTime}
                      disabled={
                        location?.state?.type == "view" && status != 0
                          ? true
                          : false
                      }
                      placeholder="Ride Time"
                      name="date"
                      className="bg-transparent"
                      type="time"
                      onChange={(e) => setRideTime(e.target.value)}
                    />
                    {rideTime?.length == "" && rideTimeErr && (
                      <p className="err-p">Enter Ride Time</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-12 col-lg-12 ps-0">
                <div className="px-1 col-sm-12 modal-label-input d-flex justify-content-center">
                  <button
                    type="button"
                    className="comn-btn bg-success px-3 py-2 me-2 mt-4"
                    onClick={(e) => handelNum(e.target.value)}
                  >
                    Add Points
                  </button>
                </div>
              </div>
              {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="px-1 col-sm-12 modal-label-input">
                  <label className="text-primary-dark"> Add Points</label>
                  <input
                    type="number"
                    id="example-if-email"
                    name="Seat"
                    value={pointNum}
                    onChange={(e) => handelNum(e.target.value)}
                    placeholder="Points"
                    disabled={location?.state?.type == "view" ? true : false}
                    // disabled={location?.state?.type == "view" && status != 0 ? true :false}
                  />
                  {pointNum?.length == "" && pointNumErr && (
                    <p className="err-p">Enter PointNum </p>
                  )}
                </div>
              </div> */}

              {pointslist?.map((item, ind) => {
                // console.log(item?.points,item?.points?.length);
                return (
                  <>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0 ">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            ponits
                          </label>

                          <select
                            value={item?.points}
                            onChange={(e) =>
                              handelPoints(e.target.value, "points", ind)
                            }
                            disabled={
                              location?.state?.type == "view" && status != 0
                                ? true
                                : false
                            }
                          >
                            <option value={""}>Select To</option>
                            {place?.map((items) => {
                              return (
                                <option value={items?.id}>
                                  {items?.title}
                                </option>
                              );
                            })}
                          </select>
                          {item?.points?.length == 0 && pointsErr && (
                            <p className="err-p">Enter points</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark">Position</label>
                        <input
                          type="number"
                          id="example-if-email"
                          name="Seat"
                          value={item?.position}
                          onChange={(e) =>
                            handelPoints(e.target.value, "position", ind)
                          }
                          placeholder="position"
                          disabled={
                            location?.state?.type == "view" && status != 0
                              ? true
                              : false
                          }
                        />
                        {item?.position?.length == 0 && pointsErr && (
                          <p className="err-p">Enter position</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark">Start Times</label>
                        <input
                          type="time"
                          id="example-if-email"
                          name="Seat"
                          value={item?.time}
                          onChange={(e) =>
                            handelPoints(e.target.value, "time", ind)
                          }
                          placeholder="Time"
                          disabled={
                            location?.state?.type == "view" && status != 0
                              ? true
                              : false
                          }
                        />
                        {item?.time?.length == 0 && pointsErr && (
                          <p className="err-p">Enter Time</p>
                        )}
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <button
                        type="button"
                        className="comn-btn bg-danger px-3 py-2 mt-4 me-4"
                        onClick={() => DeletePoints(ind)}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                );
              })}
            </div>
            {((location?.state?.type == "view" && status == 0) ||
              location?.state?.type == "add") && (
              <button
                type="button"
                className={`comn-btn bg-success px-3 py-2 me-2 mt-4 d-flex justify-content-center ${
                  btn && "opacity-50"
                }`}
                data-dismiss="modal"
                onClick={crateShare}
                disabled={btn ? true : false}
              >
                {location?.state?.type === "view" ? "Edit" : "Submit"}
              </button>
            )}
          </div>
        </div>
      </article>
    </aside>
  );
}

export default ShareCabCreate;
