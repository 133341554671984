import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import {
  useLazySupportViewQuery,
  useSupportListMutation,
  useSupportReplyMutation,
} from "../../../constants/redux/api/api";
import { useFocusEffect } from "@chakra-ui/react";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const PendingHelp = ({ setData }) => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Ticket ID",
        accessor: "id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Date and Time",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <span>{moment(value).format("DD/MM/YYYY hh:mm A")}</span>;
        },
      },

      {
        Header: "User Name",
        accessor: "supportable.name",
        Cell: ({ value, row }) => {
          return (
            <span
              style={{
                cursor: "pointer",
                "text-decoration-line": "underline",
                background: "none",
                color: "var(--bs-link-color)",
              }}
              onClick={() => {
                row.original.supportable_type == "App\\Models\\User"
                  ? navigate("/user/view-profile", {
                      state: { id: row.original.supportable_id },
                    })
                  : navigate("/driver/view-profile", {
                      state: { data: row.original.supportable },
                    });
              }}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "User ID",
        accessor: "supportable_id",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "User Type",
        accessor: "supportable_type",
        Cell: ({ value, row }) => {
          return (
            <span>{value == "App\\Models\\User" ? "Passenger" : "Driver"}</span>
          );
        },
      },

      {
        Header: "Query",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Status",
        accessor: "reply_msg",
        Cell: ({ value, row }) => {
          return (
            <p style={{ color: "blue" }}>
              {value !== null ? value : "Not  Replied"}
            </p>
          );
        },
      },
      {
        Header: "Action",
        accessor: "act",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {permissons.includes("CAN-EDIT-HELP AND SUPPORTS") ? (
                <td className="flex-ac text-center">
                  <button
                    onClick={() => viewSupport(row.original?.id)}
                    className="comn-btn bg-primary edit-btn-svg"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </td>
              ) : null}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const [list, setList] = useState([]);
  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState(false);
  const [name, setName] = useState("");
  const [view, setView] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [btn, setBtn] = useState(false);

  //RTK
  const [supportList] = useSupportListMutation();
  const [supportView] = useLazySupportViewQuery();
  const [replyApi] = useSupportReplyMutation();

  const getList = () => {
    // let params;
    // if (from && to) {
    //   params = `?start_date=${from}&end_date=${to}`;
    // } else {
    //   params = "";
    // }
    supportList()
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        setList(res?.pending_supports);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getList();
  }, []);
  // REPLY SUPPORT
  console.log(msgErr, msg, "msgErr");

  const viewSupport = (data) => {
    supportView(data)
      .unwrap()
      .then((res) => {
        // console.log(res?.support);
        setView(res?.support);
        if (res?.support?.reply_msg == null) {
          setMsg("");
        } else {
          setMsg(res?.support?.reply_msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setAddModal(true);
    setName(data);
  };

  const reply = (id) => {
    if (msg?.length == "") {
      setMsgErr(true);
    } else {
      const formdata = new FormData();
      formdata.append("support_id", id);
      formdata.append("reply_msg", msg);
      setBtn(true);
      replyApi(formdata)
        .unwrap()
        .then((res) => {
          console.log(res);
          setBtn(false);
          setAddModal(false)
          toast.success(res.message,{autoClose:1500,hideProgressBar:false})
          setMsgErr(false)
          getList()
        })
        .catch((err) => {
          console.log(err);
          setBtn(false);
          setMsgErr(false)
        });
    }
  };

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <Table columns={columns} data={list} />
        </div>
      </article>
      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Queries</h4>
          </div>
          {/* <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Issue Type
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="example-if-email"
              placeholder="Name"
              value={"type"}
              disabled
              className="bg-transparent"
            />
          </div> */}
          {/* <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Ride ID
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="example-if-email"
              placeholder="Name"
              value={"#00" + name?.id}
              disabled
              className="bg-transparent"
            />
          </div> */}
          <div className="px-1 col-sm-12 modal-label-input d-flex justify-content-end">
            <label
              style={{ fontSize: "15px", fontWeight: "bold" }}
              className="text-primary-dark"
              for=""
            >
              Ticket Id : {view?.id}
            </label>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Subject
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="example-if-email"
              placeholder="Name"
              value={view?.description}
              disabled
              className="bg-transparent"
            />
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Message
            </label>
            <textarea
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              id=""
              cols="30"
              rows="10"
              className="message-txt"
            />
            {msg?.length == "" && msgErr && (
              <p className="err-p">Enter Message</p>
            )}
          </div>
        </div>

        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2 mt-4">
          <button
            onClick={() => {
              setAddModal(false);
              setMsg("");
              setMsgErr(false)
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Close
          </button>
          <button
            // onClick={() => replyMessage()}
            onClick={() => reply(view?.id)}
            type="button"
            className={`comn-btn bg-success px-3 py-2 me-2${
              btn ? "opacity-50" : "opacity-100"
            }`}
            data-dismiss="modal"
            // disabled={btn ? true : false}
          >
            Reply
          </button>
        </div>
      </Modal>
      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Reply</h4>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Voium Niou
            </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className="message-txt"
              disabled
            >
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laborum
              id recusandae ut officiis dolorum voluptates, necessitatibus at
              perferendis, delectus eveniet porro beatae excepturi vitae rerum
              vel temporibus fugit optio soluta.
            </textarea>
          </div>
          <div className="px-1 col-sm-12 modal-label-input">
            <label className="text-primary-dark" for="">
              Description
            </label>
            <textarea name="" id="" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setEditModal(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default PendingHelp;
