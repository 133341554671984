import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt } from "@fortawesome/free-solid-svg-icons";
import { bannerList, maleAvatar, Nologo } from "../../../assets/img";
import { Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import moment from "moment/moment";
import { toast } from "react-toastify";
import {
  useCompanyAcceptMutation,
  useCompanyDriverUpdateMutation,
  useCompanyListSettingMutation,
  useDriverAcceptMutation,
  useLazyDriverRequestViewQuery,
  useLazyDriverViewRequestQuery,
} from "../../../constants/redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../../constants/redux/slice/loaderNoti";

const DriverDocumentIndividual = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.data;
  console.log("id", Id);

  const [btn, setBtn] = useState(false);

  // RTK QUERY
  // const [driverDocViewApi] = useLazyDriverRequestViewQuery();
  const [driverDocViewApi] = useLazyDriverViewRequestQuery();
  const [driverAcceptApi] = useCompanyAcceptMutation();
  const [zoneListApi] = useCompanyListSettingMutation();
  const [companyUpdateApi] = useCompanyDriverUpdateMutation();
  const [list, setList] = useState([]);
  const [listCompany, setListCompany] = useState([]);

  const [detail, setDetails] = useState([]);
  const [aadh, setAadh] = useState();
  const [lic, setLic] = useState();
  const [vehic, setVechic] = useState();
  const [rcNo, setRc] = useState();
  const [aadhars, setAadhars] = useState();
  const [insuran, setInsuran] = useState();
  const [licNo, setLicNo] = useState();

  const [userData, setUserData] = useState();
  const [licenData, setLicenData] = useState();
  const [VehicleData, seVehicleData] = useState();
  const [aadhaarAdd, setAadhaarAdd] = useState();
  const [licenAdd, setLicenAdd] = useState();
  const [reason, setReason] = useState("");
  const [reasonErr, setReasonErr] = useState(false);
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState(false);
  const [companyId, setCompanyid] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [imgModel, setImgModel] = useState(false);

  const viewDocumnet = () => {
    driverDocViewApi(Id?.id)
      .unwrap()
      .then((res) => {
        setList(res?.driver);
        setRole(res?.driver?.company_id);
        setCompanyid(res?.driver?.company_id);
        setCompanyName(res?.driver?.company_name);
        setReason(res?.driver?.reject_reason);
        let aadhaar = res?.driver?.driver_proofs?.filter((i) => i?.type == 5);
        let license = res?.driver?.driver_proofs?.filter((i) => i?.type == 4);
        let rcNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 2);
        let vehicNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 1);
        let insNum = res?.driver?.driver_proofs?.filter((i) => i?.type == 3);

        if (aadhaar) {
          let aadhaars = aadhaar[0]?.number.slice(-4);
          setAadh(aadhaars);

          let temp = JSON.parse(aadhaar[0]?.details);
          setUserData(temp);

          let add1 = [
            temp?.address?.house,
            temp?.address?.street,
            temp?.address?.po,
            temp?.address?.state,
            temp?.zip,
          ];

          setAadhaarAdd(add1);
          setAadhars(aadhaar[0]);
        }

        if (license) {
          let licen = license[0]?.number.slice(-4);
          setLic(licen);
          let temp1 = JSON.parse(license[0].details);
          setLicenData(temp1);
          setLicNo(license[0]);
        }

        if (vehicNum) {
          let temp2 = JSON.parse(vehicNum[0].details);
          seVehicleData(temp2);
          setVechic(vehicNum[0]);
        }

        if (rcNum) {
          setRc(rcNum[0]);
        }

        if (insNum) {
          setInsuran(insNum[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Id?.id) {
      viewDocumnet();
    } else {
      navigate(-1);
    }
  }, []);

  const submitHandle = (e) => {
    let val = e.target.value;
    let formData = new FormData();

    formData.append("driver_id", Id?.id);

    if (val == 2) {
      formData.append("status", val);
      // console.log("2", val);
    } else if (val == 3) {
      formData.append("status", val);
      formData.append("reject_reason", reason);
      // console.log("3", val);
    }

    if (reason?.length == 0) {
      setReasonErr(true);
    } else {
      if (val == 3) {
        const confirm = window.confirm("Are you sure to Reject?");

        if (confirm) {
          dispatch(saveLoader(true));
          setBtn(true);
          driverAcceptApi(formData)
            .unwrap()
            .then((res) => {
              if (res?.status == "success") {
                toast.success(res?.message, {
                  position: "top-right",
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  autoClose: 2000,
                });
                navigate("/company/request-list");
                setBtn(false);
                dispatch(saveLoader(false));
              }
            })
            .catch((err) => {
              setBtn(false);
              dispatch(saveLoader(false));
              console.log(err);
            });
        }
      } else {
        dispatch(saveLoader(true));
        setBtn(true);
        driverAcceptApi(formData)
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              dispatch(saveLoader(false));
              setBtn(false);
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/company/request-list");
            }
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log(err);
          });
      }
    }
  };
  //vehicle list
  const getList = () => {
    zoneListApi()
      .unwrap()
      .then((res) => {
        setListCompany(res?.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const companyUpdate = () => {
    if (role?.length == "") {
      setRoleErr(true);
    } else {
      const formData = new FormData();
      formData.append("driver_id", Id?.id);
      formData.append("company_id", companyId);
      formData.append("company_name", companyName);
      companyUpdateApi(formData)
        .unwrap()
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const selectId = (role) => {
    const temp = [...listCompany];
    const filter = temp?.find((item) => item?.id == role);
    setCompanyName(filter?.name);
    setCompanyid(filter?.id);
    setRole(role);
  };

  const image = () => {
    setImgModel(true);
  };
  return (
    <aside>
      <TopHeader type="driver-document" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="Detail-View" title="Detail View">
            <article>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      {list?.name}
                    </p>
                    <img
                      className="avatar-img"
                      src={list?.img_url || maleAvatar}
                    />
                  </div>
                  <div className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Company Update :
                          </label>
                          <select
                            name="role"
                            id=""
                            onChange={(e) => selectId(e.target.value)}
                            value={role}
                          >
                            <option>Select Type</option>
                            {Array?.isArray(listCompany) &&
                              listCompany?.map((i) => (
                                <option key={i?.id} value={i?.id}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                          {role?.length == 0 && roleErr && (
                            <p className="err-p">Select Role</p>
                          )}
                        </div>
                        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2 mt-4">
                          <button
                            className="comn-btn bg-success px-3 py-2 me-2"
                            onClick={() => companyUpdate()}
                            disabled={
                              list?.verification_status == 3 ? true : false
                            }
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver Name
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver Name"
                            className="bg-transparent"
                            type="text"
                            value={list?.name}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Driver ID
                          </label>
                          <input
                            id="example-if-email"
                            name="name"
                            placeholder="driver ID"
                            className="bg-transparent"
                            type="text"
                            value={list?.id ? "#00" + list?.id : ""}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Phone Number
                          </label>
                          <input
                            id="example-if-email"
                            name="Phone_Number"
                            placeholder="Phone Number"
                            className="bg-transparent"
                            type="text"
                            value={list?.mobile}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Email
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={list?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="roles-page mt-0 d-flex flex-wrap">
                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Aadhar
                        </label>
                        <p>{list?.driverDocument?.aadhar_no}</p>
                        <div onClick={() => image()} role="button">
                          <img
                            src={list?.driverDocument?.img_url_aadhar || Nologo}
                            alt="no"
                            className="add_img object-fit-fill w-50"
                          />
                        </div>
                      </div>

                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          QR Image
                        </label>
                        <p>&#8203;</p>
                        <img
                          src={list?.img_url_qr || Nologo}
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>
                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Licence
                        </label>
                        <p>{list?.driverDocument?.licence_no}</p>
                        <img
                          src={list?.driverDocument?.img_url_licence || Nologo}
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>

                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Rc
                        </label>
                        <p>{list?.driverDocument?.rc_no}</p>
                        <img
                          src={list?.driverDocument?.img_url_rc || Nologo}
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>

                      <div className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-2 mb-2 modal-label-input">
                        <label className="text-primary-dark" for="">
                          Insurance Image
                        </label>
                        <p>&#8203;</p>
                        <img
                          src={
                            list?.driverDocument?.img_url_insurance || Nologo
                          }
                          alt="no"
                          className="add_img object-fit-fill w-50"
                        />
                      </div>
                    </div>
                    <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 mb-3 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Vehicle Image
                      </label>
                      <p>{list?.vehicle_no}</p>
                      <div className="roles-page mt-0 d-flex flex-wrap">
                        {list?.driverVehicleImages?.map((item, ind) => {
                          return (
                            <div
                              className="form-group col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xxl-6 pl-0 mt-3 mb-3 modal-label-input"
                              key={ind}
                            >
                              <img
                                src={item?.img_url || Nologo}
                                alt="no"
                                className="add_img object-fit-fill w-50"
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Policy No
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={list?.driverDocument?.policy_no}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Policy Start Date
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={list?.driverDocument?.policy_start_date}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Policy End Date
                          </label>
                          <input
                            type="email"
                            id="example-if-email"
                            placeholder="Email"
                            className="bg-transparent"
                            value={list?.driverDocument?.policy_end_date}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Vehicle Number
                          </label>
                          <input
                            placeholder="vehicle number"
                            className="bg-transparent"
                            type="text"
                            value={list?.vehicle_no}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-12 col-sm-12 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Address
                          </label>
                          <p
                            className="address-request textarea-height"
                            disabled
                          >
                            {list?.address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {btn == true ? (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Reason
                            </label>
                            <textarea
                              placeholder="reason"
                              type="text"
                              name="reason"
                              className="bg-transparent"
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                            {reason?.length == 0 && reasonErr && (
                              <span className="err">enter reason</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : null}

              {list?.verification_status == 3 ? (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Reason
                            </label>
                            <textarea
                              placeholder="reason"
                              type="text"
                              name="reason"
                              className="bg-transparent"
                              disabled
                              value={reason}
                              onChange={(e) => setReason(e.target.value)}
                            />
                            {reason?.length == 0 && reasonErr && (
                              <span className="err">enter reason</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              ) : null}
              {/* <Modal
                className="table-modal"
                show={imgModel}
                onHide={() => setImgModel(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <div className="px-2 py-2 col-12 d-flex flex-wrap">
                  <img
                    className="px-2 py-12 col-12 d-flex flex-wrap"
                    src={list?.img_url || maleAvatar}
                  />
                </div>
              </Modal> */}
              <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                {btn == false ? (
                  <button
                    className="comn-btn bg-danger px-3 py-2 me-2"
                    onClick={() => setBtn(true)}
                    disabled={list?.verification_status == 3 ? true : false}
                  >
                    Reject
                  </button>
                ) : null}

                {btn == true ? (
                  <button
                    className="comn-btn bg-danger px-3 py-2 me-2"
                    value={3}
                    onClick={(e) => submitHandle(e)}
                  >
                    Reject
                  </button>
                ) : null}

                {list?.verification_status == 3 ? null : (
                  <button
                    className="comn-btn bg-success px-3 py-2 me-2"
                    value={2}
                    onClick={(e) => submitHandle(e)}
                  >
                    Accept
                  </button>
                )}
              </div>
            </article>
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default DriverDocumentIndividual;
