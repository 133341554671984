import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import { useCompanyPlacesAddMutation, useCompanyPlacesEditMutation, useLazyCompanyPlacesViewQuery } from "../../../constants/redux/api/api";
import TopHeader2 from "../../../component/TopHeader2";
const AddPlace = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  console.log("id",Id)
  const type = location?.state?.type;

  //RTK

  const [input, setInput] = useState([]);
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [btn, setBtn] = useState(false);
  const [title, seTitle] = useState("");
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [companyPlacesView] = useLazyCompanyPlacesViewQuery();
  const [companyPlacesEdit] = useCompanyPlacesEditMutation();
  const [companyPlacesAdd] = useCompanyPlacesAddMutation();
  const viewPlace =()=>{
    companyPlacesView(Id)
    .unwrap()
    .then(res=>{
      console.log(res);
      let data=res?.place
      seTitle(data?.title)
      setAddress(data?.address)
      setLatitude(data?.latitude)
      setLongitude(data?.longitude
      )
    })
    .catch(err=>{
      console.log(err);
    })
  }
  useEffect(()=>{
    if(Id&&type=="editPlace"){
        console.log("edit")
      viewPlace()
    }
    },[])

    const addPlace = ()=>{
        const formdata = new FormData();
        formdata.append("title",title)
        formdata.append("address",address)
        formdata.append("longitude",longitude)
        formdata.append("latitude",latitude)
        companyPlacesAdd(formdata)
        .unwrap()
        .then((res)=>{
            console.log(res)
            if(res.status == "success"){
                toast.success(res.message,{autoClose:1500,hideProgressBar:false})
                navigate(-1)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    const editPlace = ()=>{
        const formdata = new FormData();
        formdata.append("title",title)
        formdata.append("address",address)
        formdata.append("longitude",longitude)
        formdata.append("latitude",latitude)
        formdata.append("_method","PUT")
        companyPlacesEdit({payload:formdata,ID:Id})
        .unwrap()
        .then((res)=>{
            console.log(res)
            if(res.status == "success"){
                toast.success(res.message,{autoClose:1500,hideProgressBar:false})
                navigate(-1)
            }
        })
        .catch((err)=>{
            console.log(err)
        })
    }

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader2 type="edit-place" />
      ) : (
        <TopHeader2 type="add-place" />
      )}
      {(type == "addPlace" || type=="editPlace") && (
        <article className="p-3">
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Title
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="name"
                        onChange={(e) => seTitle(e.target.value)}
                        placeholder="Title"
                        value={title}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Address
                      </label>
                      <input
                        type="Name"
                        id="example-if-email"
                        name="username"
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="address"
                        value={address}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Latitude
                      </label>
                      <input
                        type="number"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setLatitude(e.target.value)}
                        placeholder="Latitude"
                        value={latitude}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Longitude
                      </label>
                      <input
                        type="number"
                        id="example-if-email"
                        name="number"
                        onChange={(e) => setLongitude(e.target.value)}
                        placeholder="Longitude"
                        value={longitude}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-auto ms-auto">
                  <button
                    type="button"
                    className="comn-btn bg-success-gradient px-3 py-2 me-2 my-4"
                    disabled={btn ? true : false}
                    onClick={()=>{type == "editPlace"?editPlace():addPlace()}}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </article>
      )}
      {/* {(type == "addPlace" ||
        type == "editPlace" )&& (
          <article className="p-3">
            <div className="p-3">
              <div className="p-3 bg-white rounded-2">
                <Form className="d-flex flex-wrap">
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          From Pincode
                        </label>
                        <input
                          type="number"
                          id="example-if-email"
                          name="name"
                          onChange={(e) => setArea(e.target.value)}
                          placeholder="From date"
                          value={area}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <label className="text-primary-dark" for="">
                          To Pincode
                        </label>
                        <input
                          type="number"
                          id="example-if-email"
                          name="username"
                          onChange={(e) => setCity(e.target.value)}
                          placeholder="To Pincode"
                          value={city}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-auto ms-auto">
                    <button
                      type="button"
                      className="comn-btn bg-success-gradient px-3 py-2 me-2"
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </article>
        )} */}
    </aside>
  );
};

export default AddPlace;
